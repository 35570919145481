import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'


const baseQuery = fetchBaseQuery({
  baseUrl: "https://teentogether.stackerbee.com",
  prepareHeaders: (headers) => {
    const token = localStorage.getItem('token');
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && (result.error.status === 401 || result.error.status === 403)) {

    localStorage.removeItem('token');

  }

  return result;
};


export const voiceofthedaylistApi = createApi({
  reducerPath: 'voiceofthedaylistApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getVoiceofthedaylist: builder.query({
      query: ({ page = 1, pageSize = 10, searchTerm = '' }) => ({
        url: `/voice-day/voice-list/`,
        params: {
          page,
          page_size: pageSize,
          category: searchTerm,  // Assuming your API filters by category or search term
        },
      }),
    }),
    postVoiceoftheday: builder.mutation({
      query: (data) => ({
        url: `/voice-day/voice-create/`,
        method:'POST',
        body:data
      })
    }),
    putVoiceoftheday: builder.mutation({
      query: ({data,id}) => ({
        url: `/voice-day/voice-update/${id}/`,
        method:'PUT',
        body:data
      })
    }),
  }),
})

export const { useGetVoiceofthedaylistQuery,usePostVoiceofthedayMutation,usePutVoiceofthedayMutation } = voiceofthedaylistApi