import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import token from './state/token'
import { adminApi } from './api/adminapi'
import { meditationApi } from './api/meditationapi'
import { meditationCategoryApi } from './api/meditation-category-api'
import { concentrateApi } from './api/concentrate-api'
import { voiceofthedaylistApi } from './api/voiceofthedayapi'
import { doctorApi } from './api/doctorapi'
import { avatarsApi } from './api/avatars-api'

export const store = configureStore({
  reducer: {
    token:token,
    [adminApi.reducerPath]: adminApi.reducer,
    [meditationApi.reducerPath]: meditationApi.reducer,
    [meditationCategoryApi.reducerPath]: meditationCategoryApi.reducer,
    [concentrateApi.reducerPath]: concentrateApi.reducer,
    [voiceofthedaylistApi.reducerPath]: voiceofthedaylistApi.reducer,
    [doctorApi.reducerPath]: doctorApi.reducer,
    [avatarsApi.reducerPath]: avatarsApi.reducer,

  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(adminApi.middleware).concat(meditationApi.middleware).concat(meditationCategoryApi.middleware).concat(concentrateApi.middleware).concat(voiceofthedaylistApi.middleware).concat(doctorApi.middleware).concat(avatarsApi.middleware),
})

setupListeners(store.dispatch)