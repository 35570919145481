import React, { useEffect, useRef, useState } from "react";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useLocation, useNavigate } from "react-router-dom";
import Loadercomp from "../../../components/Loadercomp";
import beat from "../../../assets/bit.png";
import { FaPause, FaPlay } from "react-icons/fa";
import Breadcup from "../../../components/Breadcup";

const Viewuser = () => {
  const location = useLocation();
  const nvg = useNavigate();
  const [data, setdata] = useState({});
  const [srtloader, setsrtloader] = useState(true);
  const audioRef = useRef(null);

  const handleEnded = () => {
    setIsPlaying(false);
  };

  const [isPlaying, setIsPlaying] = useState(false);
  // Function to toggle play/pause
  const togglePlayPause = () => {
    const audio = audioRef.current;
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  useEffect(() => {
    if (location.state.data) {
      setdata(location.state.data);
      setsrtloader(false);
    }
  }, [location]);

  return (
    <div style={{ width: "100%", minHeight: "100vh" }}>
      <div className="dashboardcontent px-2">
        <Breadcup name={"Meditation"} secondname={"View Meditation"} />
        <div
          className="container-fuild px-2 mb-1"
          style={{ overflow: "hidden" }}
        >
          <div className="row bg-white ">
            <div
              className="col-lg-12 d-flex justify-content-between py-2"
              style={{ color: "#0C5398" }}
            >
              <p
                className="m-0 customfont teenheadertitle py-2"
                style={{ color: "#62153C" }}
              >
                Meditation
              </p>
              <div className="addnew d-block mb-2">
                <button
                  className="btn "
                  type="button"
                  onClick={() => {
                    nvg("/meditationlist");
                  }}
                  style={{
                    outline: "none",
                    background: "white",
                    color: "#00A192",
                    fontSize: "19px",
                    fontWeight: "550",
                  }}
                >
                  {" "}
                  X{" "}
                </button>
              </div>
            </div>
          </div>
        </div>

        {srtloader == true ? (
          <div className="container-fuild bg-white">
            {" "}
            <div
              className="col-12 d-flex justify-content-center"
              style={{ gap: "4px", position: "absolute", width: "70%" }}
            >
              <div className="px-2">
                {" "}
                <Loadercomp size={100} />
              </div>
            </div>
          </div>
        ) : (
          <div className="container-fuild pb-4 pt-3 px-2 bg-white">
            <div
              className="row bg-white round"
              style={{
                border: "1px solid #E0E0E0",
                margin: "10px 0px",
                borderRadius: "3px",
              }}
            >
              <div className="col-lg-6 px-4 pt-3">
                <div className="row">
                  <div className="col-md-3 col-4 d-flex align-items-center">
                    <label htmlFor="" className="form-label teenfontsize m-0">
                      Title
                    </label>
                  </div>
                  <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                    <input
                      type="text"
                      className="form-control teenfontsize2"
                      value={data.title}
                      readOnly
                      placeholder="Enter Title"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 px-4 pt-3">
                <div className="row">
                  <div className="col-md-3 col-4 d-flex align-items-center">
                    <label
                      htmlFor=""
                      className="form-label teenfontsize customw m-0"
                    >
                      Category{" "}
                    </label>
                  </div>
                  <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                    <input
                      type="text"
                      className="form-control teenfontsize2"
                      value={data.category_by_name}
                      readOnly
                      placeholder="Enter Category"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 px-4 pt-3">
                <div className="row">
                  <div className="col-md-3 col-4 d-flex align-items-center">
                    <label htmlFor="" className="form-label teenfontsize m-0">
                      Uploaded At{" "}
                    </label>
                  </div>
                  <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                    <input
                      type="datetime-local"
                      className="form-control teenfontsize2"
                      value={new Date(data.uploaded_at)
                        .toISOString()
                        .slice(0, 16)}
                      readOnly
                      placeholder="Enter Title"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 px-4 pt-3">
                <div className="row">
                  <div className="col-md-3 col-4 d-flex align-items-center">
                    <label htmlFor="" className="form-label teenfontsize m-0">
                      Created by
                    </label>
                  </div>
                  <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                    <input
                      type="text"
                      className="form-control teenfontsize2"
                      value={data.created_by_name}
                      readOnly
                      placeholder="Enter Title"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 px-4 pb-3 pt-3">
                <div className="row">
                  <div className="col-md-3 col-4 d-flex align-items-center">
                    <label htmlFor="" className="form-label teenfontsize m-0">
                      Upload Audio
                    </label>
                  </div>
                  <div className="col-lg-9 col-md-8 col-8 d-flex">
                    <div
                      className="d-flex"
                      style={{
                        border: "1px solid #E0E0E0",
                        background: "#e9ecef",
                        width: "100%",
                        justifyContent: "space-between",
                        padding: "0px 12px",
                        borderRadius: ".375rem",
                      }}
                    >
                      <p
                        className="m-0 py-1 teenfontsize2 d-flex align-items-center"
                        style={{ fontWeight: "600", gap: "4px" }}
                      >
                        {" "}
                        <span>
                          {" "}
                          <img src={beat} style={{ height: "24px" }} alt="" />
                        </span>{" "}
                        {data.audio.split("/").pop()}
                      </p>
                      <p
                        className="m-0 py-1 d-flex align-items-center"
                        onClick={togglePlayPause}
                      >
                        {isPlaying ? (
                          <FaPause fontSize={13} />
                        ) : (
                          <FaPlay fontSize={13} />
                        )}
                      </p>
                    </div>
                    <div>
                      <audio ref={audioRef} onEnded={handleEnded}>
                        <source src={data.audio} type="audio/mpeg" />
                      </audio>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Viewuser;
