import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import { GiHamburgerMenu } from "react-icons/gi";
import img from "./assets/teenlogo.jpeg";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import img1 from "./assets/dashboard.png";
import img2 from "./assets/content.png";
import img3 from "./assets/expert.png";
import img4 from "./assets/icons10.png";
import img9 from "./assets/logout 2.png";
import img11 from "./assets/social-media.png";
import img111 from "./assets/analytics.png";
import img112 from "./assets/financial-reporting.png";
import img113 from "./assets/settings.png";
import img114 from "./assets/notification.png";
import img115 from "./assets/energy-system.png";
import img116 from "./assets/customer-service.png";
import img117 from "./assets/Lotus.png";
import img118 from "./assets/logo3.png";
import { gettoken, removeToken } from "./Localstorage/Store";
import { useState } from "react";
import Header from "./components/Header";

const Sidebarmenu = ({ children }) => {
  const token = gettoken();
  const nvg = useNavigate();
  const [hideimg, sethideimg] = useState(false);
  const { collapseSidebar } = useProSidebar();
  const location = useLocation();

  const logoutevt = async () => {
    removeToken();
    nvg("/");
  };


  const hideorshow = () => {
    sethideimg(!hideimg);
  };

  const result = location.pathname.substring(
    0,
    location.pathname.lastIndexOf("/")
  );



  if (!token) {
    return <div style={{ background: "#F3F6FA" }}>{children}</div>;
  } else {
    return (
      <div style={{ display: "flex", width: "100%" }}>
        <Sidebar className="sidebarcum" defaultCollapsed="close">
          <div>
            <Menu className="nothover teenfontsize abc">
              <MenuItem
                className="nothover teenfontsize abc"
                style={{ borderBottom: "1px solid #D9D9D9" }}
                icon={
                  hideimg == true ? (
                    <GiHamburgerMenu
                      children="logobtn"
                      fontSize={23}
                      onClick={() => {
                        collapseSidebar();
                        hideorshow();
                      }}
                      color="#00A192"
                    />
                  ) : (
                    <img
                      src={img118}
                      onClick={() => {
                        collapseSidebar();
                        hideorshow();
                      }}
                      alt="qwerty"
                    />
                  )
                }
              >
                {hideimg == true ? (
                  <img src={img} alt="qwerty" style={{ width: "99%" }} />
                ) : (
                  ""
                )}
              </MenuItem>
            </Menu>
            <Menu>
              <NavLink
                to="/dashboard"
                className={
                  location.pathname === "/dashboard" ? "nav active" : "nav"
                }
              >
                <MenuItem
                  className="nothover teenfontsize"
                  icon={<img src={img1} alt="qwert" />}
                >
                  {" "}
                  Dashboard
                </MenuItem>
              </NavLink>
              <NavLink
                to="/meditationlist"
                className={
                  location.pathname === "/meditationlist" ||
                  location.pathname === "/add-meditation" ||
                  location.pathname === "/edit-meditation" ||
                  location.pathname === "/view-meditation"
                    ? "nav active"
                    : "nav"
                }
              >
                <MenuItem
                  className="nothover teenfontsize"
                  icon={<img src={img117} alt="qwdfgerct" />}
                >
                  Meditation
                </MenuItem>
              </NavLink>
              <NavLink
                to="/userlist"
                className={
                  location.pathname === "/userlist" ||
                  result === "/userdetail" ||
                  result === "/edituser"
                    ? "nav active"
                    : "nav"
                }
              >
                <MenuItem
                  className="nothover teenfontsize"
                  icon={<img src={img4} alt="qwdfgerct" />}
                >
                  User Management
                </MenuItem>
              </NavLink>
              <NavLink
                to="/voice-of-the-day-list"
                className={
                  location.pathname === "/voice-of-the-day-list" ||
                  result === "/add-voice-of-the-day" ||
                  result === "/edituser"
                    ? "nav active"
                    : "nav"
                }
              >
                <MenuItem
                  className="nothover teenfontsize"
                  icon={<img src={img4} alt="qwdfgerct" />}
                >
                  Voice of the Day
                </MenuItem>
              </NavLink>
              <NavLink
                to="/productlist/0"
                className={
                  result === "/editproduct" ||
                  result === "/productdetail" ||
                  location.pathname === "/addproduct"
                    ? "nav active"
                    : "nav"
                }
              >
                <MenuItem
                  className="nothover teenfontsize"
                  icon={<img src={img2} alt="qwewrt" />}
                >
                  {" "}
                  Content Management{" "}
                </MenuItem>
              </NavLink>
              <NavLink
                to="/corporatelist/0"
                className={
                  location.pathname === "/addcorporate" ||
                  result === "/corporatedetail" ||
                  result === "/editcorporate"
                    ? "nav active"
                    : "nav"
                }
              >
                <MenuItem
                  className="nothover teenfontsize"
                  icon={<img src={img3} alt="qwerct" />}
                >
                  {" "}
                  Expert Management
                </MenuItem>
              </NavLink>
              <NavLink
                to="/agencylist/0"
                className={
                  location.pathname === "/addagency" ||
                  result === "/agencydetail" ||
                  result === "/editagency"
                    ? "nav active"
                    : "nav"
                }
              >
                <MenuItem
                  className="nothover teenfontsize"
                  icon={<img src={img11} alt="qwerct" />}
                >
                  {" "}
                  Media Management
                </MenuItem>
              </NavLink>
              <NavLink
                to="/agencylist/0"
                className={
                  location.pathname === "/addagency" ||
                  result === "/agencydetail" ||
                  result === "/editagency"
                    ? "nav active"
                    : "nav"
                }
              >
                <MenuItem
                  className="nothover teenfontsize"
                  icon={<img src={img111} alt="qwerct" />}
                >
                  {" "}
                  Reports & Analytics
                </MenuItem>
              </NavLink>
              <NavLink
                to="/agencylist/0"
                className={
                  location.pathname === "/addagency" ||
                  result === "/agencydetail" ||
                  result === "/editagency"
                    ? "nav active"
                    : "nav"
                }
              >
                <MenuItem
                  className="nothover teenfontsize"
                  icon={<img src={img112} alt="qwerct" />}
                >
                  {" "}
                  Financial Reports
                </MenuItem>
              </NavLink>
              <NavLink
                to="/agencylist/0"
                className={
                  location.pathname === "/addagency" ||
                  result === "/agencydetail" ||
                  result === "/editagency"
                    ? "nav active"
                    : "nav"
                }
              >
                <MenuItem
                  className="nothover teenfontsize"
                  icon={<img src={img115} alt="qwerct" />}
                >
                  {" "}
                  Integration Management
                </MenuItem>
              </NavLink>
              <NavLink
                to="/agencylist/0"
                className={
                  location.pathname === "/addagency" ||
                  result === "/agencydetail" ||
                  result === "/editagency"
                    ? "nav active"
                    : "nav"
                }
              >
                <MenuItem
                  className="nothover teenfontsize"
                  icon={<img src={img114} alt="qwerct" />}
                >
                  {" "}
                  Notification
                </MenuItem>
              </NavLink>
              <NavLink
                to="/agencylist/0"
                className={
                  location.pathname === "/addagency" ||
                  result === "/agencydetail" ||
                  result === "/editagency"
                    ? "nav active"
                    : "nav"
                }
              >
                <MenuItem
                  className="nothover teenfontsize"
                  icon={<img src={img113} alt="qwerct" />}
                >
                  {" "}
                  Settings & Configurations
                </MenuItem>
              </NavLink>
              <NavLink
                to="/agencylist/0"
                className={
                  location.pathname === "/addagency" ||
                  result === "/agencydetail" ||
                  result === "/editagency"
                    ? "nav active"
                    : "nav"
                }
              >
                <MenuItem
                  className="nothover teenfontsize"
                  icon={<img src={img116} alt="qwerct" />}
                >
                  Support & Help
                </MenuItem>
              </NavLink>
              <NavLink to="/" onClick={logoutevt} className="nav">
                <MenuItem
                  className="nothover teenfontsize"
                  icon={<img src={img9} alt="qwerct" />}
                >
                  Log Out
                </MenuItem>
              </NavLink>
            </Menu>
          </div>
        </Sidebar>
        <div style={{ width: "100%" }}>
          <Header />
          {children}
        </div>
      </div>
    );
  }
};

export default Sidebarmenu;
