import React from "react";
import img1 from "../../assets/Ellipse 27.png";
import img2 from "../../assets/Ellipse 28.png";
import img3 from "../../assets/Ellipse 29.png";
import img5 from "../../assets/money.png";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  BarChart,
  Bar,
  LabelList,
} from "recharts";
import Breadcupdash from "../../components/Breadcupdash";
import { useState } from "react";
import Loadercomp from "../../components/Loadercomp";
const Dashboard = () => {






  const [srtloader, setsrtloader] = useState(false);
  const [cusfromdate, setcusfromdate] = useState(null);
  const [remifromdate, setremifromdate] = useState(null);
  const [custodate, setcustodate] = useState(null);
  const [remitodate, setremitodate] = useState(null);

  const [Data2,setData2] = useState([])
  const [Data3,setData3] = useState([])
  const [Data4,setData4] = useState([])
  const [Data5,setData5] = useState([])


  const data = [
    {
      rank: 1,
      name: "ajay",
      number: 5,
    },
    {
      rank: 2,
      name: "fggfds",
      number: 24,
    },
    {
      rank: 3,
      name: "sdfg",
      number: 20,
    },
    {
      rank: 4,
      name: "kjhgfd",
      number: 5,
    },
    {
      rank: 5,
      name: "dfgh",
      number: 30,
    },
    {
      rank: 6,
      name: "sdfgh",
      number: 10,
    },
    {
      rank: 7,
      name: "asdfgh",
      number: 20,
    },
    {
      rank: 8,
      name: "sdf",
      number: 5,
    },
    {
      rank: 9,
      name: "sdfghjk",
      number: 14,
    },
    {
      rank: 10,
      name: "ajsdfghay",
      number: 1,
    },
    {
      rank: 11,
      name: "ajay",
      number: 7,
    },
    {
      rank: 12,
      name: "fggfds",
      number: 10,
    },
    {
      rank: 13,
      name: "sdfg",
      number: 8,
    },
    {
      rank: 14,
      name: "kjhgfd",
      number: 4,
    },
    {
      rank: 15,
      name: "dfgh",
      number: 4,
    },
    {
      rank: 16,
      name: "sdfgh",
      number: 17,
    },
    {
      rank: 17,
      name: "asdfgh",
      number: 12,
    },
    {
      rank: 18,
      name: "sdf",
      number: 23,
    },
    {
      rank: 19,
      name: "sdfghjk",
      number: 14,
    },
    {
      rank: 20,
      name: "ajsdfghay",
      number: 1,
    },
  ];

  const pdata = [
    {
      rank: 5000,
      name: "Mon",
      number: 11000,
    },
    {
      rank: 7000,
      name: "Tue",
      number: 9000,
    },
    {
      rank: 8000,
      name: "Wed",
      number: 5000,
    },
    {
      rank: 4000,
      name: "Thu",
      number: 5700,
    },
    {
      rank: 10000,
      name: "Fri",
      number: 4000,
    },
    {
      rank: 11000,
      name: "Sat",
      number: 3000,
    },
    {
      rank: 2000,
      name: "Sun",
      number: 7000,
    },
  ];





  return (
    <div style={{ width: "100%", minHeight: "100vh" }}>
      <div className="dashboardcontent">
        <Breadcupdash name={"Dashboard"} />
  


  {/* live production code start here  */}
        {srtloader == true ? <div className="container-fuild bg-white"> <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"100%"}}>
        <div className='px-2'> <Loadercomp size={100} /></div>
        </div></div> : <div className="container-fuild py-4" style={{paddingLeft:"0px",paddingRight:"0px"}}>
          <div className="row" style={{padding:'0px 14px'}}>

           
            <div className="col-lg-4 mb-2 col-md-6 col-12 dbox">
              <div className="row bg-white py-3 d-flex align-items-center rounded py-2">
                <div className="col-3">
                  <img src={img1} alt="" />
                </div>
                <div className="col-9">
                  <p className="dbold">{12}</p>
                  <p className="pbold">Total Doctor</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 mb-2 col-md-6 col-12 dbox">
              <div className="row bg-white py-3 d-flex align-items-center rounded py-2">
                <div className="col-3">
                  <img src={img5} alt="" />
                </div>
                <div className="col-9">
                  <p className="dbold">{12}</p>
                  <p className="pbold">Total User</p>
                </div>
              </div>
            </div>
        
            <div className="col-lg-4  mb-2 col-md-6 col-12 dbox">
              <div className="row bg-white py-3 d-flex align-items-center rounded py-2">
                <div className="col-3">
                  <img src={img3} alt="" />
                </div>
                <div className="col-9">
                  <p className="dbold">{20}</p>
                  <p className="pbold">Total Parent</p>
                </div>
              </div>
            </div>



            <div className="col-lg-6 col-md-12 dbox">
              <div className="row rounded bg-white mt-3">
                <div className="col-12 d-flex justify-content-between align-items-center">
                  <div className="dtext py-2" style={{fontSize:'18px',color:'#62153c'}}>User </div>
                  <div className="lastlo d-flex py-2" style={{ gap: "7px" }}>
                    <select
                      name=""
                      id=""
                      style={{fontSize:'14px'}}
                      className="form-select custselect"
                      aria-label="Default select example"
                    >
                      <option value="qwerty">March</option>
                      <option value="ytrewq">ytrewq</option>
                      <option value="zxcvb">zxcvb</option>
                      <option value="bvcxz">bvcxz</option>
                    </select>
                    <select
                      name=""
                      id=""
                      style={{fontSize:'14px'}}
                      className="form-select custselect"
                      aria-label="Default select example"
                    >
                      <option value="qwerty">2023</option>
                      <option value="ytrewq">ytrewq</option>
                      <option value="zxcvb">zxcvb</option>
                      <option value="bvcxz">bvcxz</option>
                    </select>
                  </div>
                </div>
                <div className="col-12 px-2 ">
                  {" "}
                  <div className="underline"></div>
                </div>
                <div className="col-12 py-3 ">
                  <ResponsiveContainer width="100%" aspect={2}>
                    <LineChart
                      data={data}
                      margin={{ top: 2, right: 39, bottom: 0, left: 0 }}
                    >
                      <CartesianGrid />
                      <XAxis dataKey="rank" intercept={"preserveStartEnd"} />
                      <YAxis />
                      <Tooltip
                        contentStyle={{
                          background:
                            "#00A192",
                          border: "1px solid ",
                          outline: "none",
                        }}
                      />
                      <Line dataKey="number" stroke="#00A192" />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>
</div>
<div className="col-lg-6 col-md-12 dbox">
              <div className="row rounded bg-white mt-3">
                <div className="col-12 d-flex justify-content-between align-items-center">
                  <div className="dtext py-2" style={{fontSize:'18px',color:'#62153c'}}>Doctor</div>
                  <div className="lastlo d-flex py-2" style={{ gap: "7px" }}>
                    <select
                      name=""
                      id=""
                      style={{fontSize:'14px'}}
                      className="form-select custselect"
                      aria-label="Default select example"
                    >
                      <option value="qwerty">March</option>
                      <option value="ytrewq">ytrewq</option>
                      <option value="zxcvb">zxcvb</option>
                      <option value="bvcxz">bvcxz</option>
                    </select>
                    <select
                      name=""
                      id=""
                      style={{fontSize:'14px'}}
                      className="form-select custselect"
                      aria-label="Default select example"
                    >
                      <option value="qwerty">2023</option>
                      <option value="ytrewq">ytrewq</option>
                      <option value="zxcvb">zxcvb</option>
                      <option value="bvcxz">bvcxz</option>
                    </select>
                  </div>
                </div>
                <div className="col-12 px-2 ">
                  {" "}
                  <div className="underline"></div>
                </div>
                <div className="col-12 py-3 ">
                  <ResponsiveContainer width="100%" aspect={2}>
                    <BarChart
                      width="100%"
                      aspect={2}
                      data={pdata}
                      margin={{ top: 15, right: 10, left: 20, bottom: 5 }}
                    >
                      <XAxis dataKey="name"></XAxis>
                      <Tooltip
                        contentStyle={{
                          background:
                            "#00A192",
                          border: "1px solid ",
                          outline: "none",
                        }}
                      />
                      <Bar dataKey="rank" fill="#00A192">
                        <LabelList
                          dataKey="rank"
                          fill="white"
                          angle="90"
                          fontSize={10}
                        />
                      </Bar>
                      <Tooltip
                        contentStyle={{
                          background:
                            "#00A192",
                          border: "1px solid ",
                          outline: "none",
                        }}
                      />
                      <Bar dataKey="number" fill="#EE475A">
                        <LabelList
                          dataKey="number"
                          fill="white"
                          angle="90"
                          fontSize={10}
                        />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
       
        </div>}
      </div>
    </div>
  );
};

export default Dashboard;
