import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { BsFillEyeFill } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import ReactPaginate from "react-paginate";
import { useGetMeditationlistQuery } from "../../../store/api/meditationapi";
import Loadercomp from "../../../components/Loadercomp";
import { useGetDoctorlistQuery } from "../../../store/api/doctorapi";

const Table = () => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  // const [data, setdata] = useState([]);
  // const [isLoading, setisLoading] = useState(false);
  // const [error, seterror] = useState();

  // Fetch data with dynamic parameters using RTK Query
  const { data, error, isLoading } = useGetDoctorlistQuery({
    page: currentPage,
    pageSize,
    searchTerm: search,
  });

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1); // Reset to first page on new search
  };

  const handlePageChange = (selectedPage) => {
    const page = selectedPage.selected + 1;
    setCurrentPage(page);
  };

  const handlePageSizeChange = (e) => {
    const size = e.target.value;
    setPageSize(size);
    setCurrentPage(1); // Reset to first page when changing page size
  };

  return isLoading ? (
    <div className="loading d-flex justify-content-center">
      <Loadercomp />
    </div>
  ) : error ? (
    <div className="d-flex justify-content-center">
      <span> Error loading data </span>
    </div>
  ) : (
    <div className="table-container">
      {/* Search Input */}
      <div className="d-flex justify-content-end">
        <input
          type="text"
          placeholder="Search"
          value={search}
          onChange={handleSearch}
          style={{ width: "fit-content", fontSize: "13px" }}
          className="form-control"
        />
      </div>

      {/* Table */}
      <table className="styled-table mt-1">
        <thead>
          <tr>
            <th>S.NO</th>
            <th>Full Name</th>
            <th>Price</th>
            <th>Designation</th>
            <th>Email ID</th>
            <th>Mobile No.</th>
            <th>Created Date & Time</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data?.doc?.length > 0 ? (
            data.doc.map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.accounts.first_name} {item.accounts.last_name}</td>
                <td>{item.amount}</td>
                <td>{item.accounts.designation}</td>
                <td>{item.accounts.email}</td>
                <td>{item.accounts.phone_number}</td>
                <td>{new Date(item.created_at).toLocaleString()}</td>
                <td>
                  <NavLink to={`/view-user`} state={{ data: item }}>
                    <BsFillEyeFill
                      style={{ paddingRight: "5px" }}
                      fontSize={21}
                      color="#00A192"
                    />
                  </NavLink>
                  <NavLink to={`/view-user`} state={{ data: item }}>
                    <BiEdit fontSize={16} color="#00A192" />
                  </NavLink>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={6} style={{ textAlign: "center", fontSize: "15px" }}>
                <span style={{ fontSize: "14px" }}>
                  No data available in table
                </span>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="d-flex" style={{ justifyContent: "space-between" }}>
        {/* Page Size Selector */}
        <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
          Show
          <select
            value={pageSize}
            style={{ outline: "none" }}
            onChange={handlePageSizeChange}
            className="page-size-selector"
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
          </select>{" "}
          entries
        </div>

        {/* Pagination */}
        <ReactPaginate
          previousLabel={"previous"}
          nextLabel={"next"}
          breakLabel={"..."}
          pageCount={Math.ceil(data?.count / pageSize)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </div>
    </div>
  );
};

export default Table;
