import React, { useEffect, useState } from "react";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useLocation, useNavigate } from "react-router-dom";
import Loadercomp from "../../../components/Loadercomp";
import Breadcup from "../../../components/Breadcup";

const Viewvoiceoftheday = () => {
  const location = useLocation();
  const nvg = useNavigate();
  const [data, setdata] = useState({});
  const [srtloader, setsrtloader] = useState(true);


  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  useEffect(() => {
    if (location.state.data) {
      setdata(location.state.data);
      setsrtloader(false);
      console.log("the day of view",location.state)
    }
  }, [location]);

  return (
    <div style={{ width: "100%", minHeight: "100vh" }}>
      <div className="dashboardcontent px-2">
        <Breadcup name={"Voice of the day"} secondname={"View Voice of the day"} />
        <div
          className="container-fuild px-2 mb-1"
          style={{ overflow: "hidden" }}
        >
          <div className="row bg-white ">
            <div
              className="col-lg-12 d-flex justify-content-between py-2"
              style={{ color: "#0C5398" }}
            >
              <p
                className="m-0 customfont teenheadertitle py-2"
                style={{ color: "#62153C" }}
              >
              View Voice of the day
              </p>
              <div className="addnew d-block mb-2">
                <button
                  className="btn "
                  type="button"
                  onClick={() => {
                    nvg("/voice-of-the-day-list");
                  }}
                  style={{
                    outline: "none",
                    background: "white",
                    color: "#00A192",
                    fontSize: "19px",
                    fontWeight: "550",
                  }}
                >
                  {" "}
                  X{" "}
                </button>
              </div>
            </div>
          </div>
        </div>

        {srtloader == true ? (
          <div className="container-fuild bg-white">
            {" "}
            <div
              className="col-12 d-flex justify-content-center"
              style={{ gap: "4px", position: "absolute", width: "70%" }}
            >
              <div className="px-2">
                {" "}
                <Loadercomp size={100} />
              </div>
            </div>
          </div>
        ) : (
          <div className="container-fuild pb-4 pt-3 px-2 bg-white">
            <div
              className="row bg-white round"
              style={{
                border: "1px solid #E0E0E0",
                margin: "10px 0px",
                borderRadius: "3px",
              }}
            >
              <div className="col-lg-6 px-4 pt-3">
                <div className="row">
                  <div className="col-md-3 col-4 d-flex align-items-center">
                    <label htmlFor="" className="form-label teenfontsize m-0">
                      Title
                    </label>
                  </div>
                  <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                    <input
                      type="text"
                      className="form-control teenfontsize2"
                      value={data.title}
                      readOnly
                      placeholder="Enter Title"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 px-4 pt-3">
                <div className="row">
                  <div className="col-md-3 col-4 d-flex align-items-center">
                    <label
                      htmlFor=""
                      className="form-label teenfontsize customw m-0"
                    >
                      Content{" "}
                    </label>
                  </div>
                  <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                    <input
                      type="text"
                      className="form-control teenfontsize2"
                      value={data.content}
                      readOnly
                      placeholder="Enter Content"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 px-4 pt-3">
                <div className="row">
                  <div className="col-md-3 col-4 d-flex align-items-center">
                    <label htmlFor="" className="form-label teenfontsize m-0">
                      Uploaded At{" "}
                    </label>
                  </div>
                  <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                    <input
                      type="datetime-local"
                      className="form-control teenfontsize2"
                      value={new Date(data.create_date)
                        .toISOString()
                        .slice(0, 16)}
                      readOnly
                      placeholder="Enter Title"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 px-4 pt-3">
                <div className="row">
                  <div className="col-md-3 col-4 d-flex align-items-center">
                    <label htmlFor="" className="form-label teenfontsize m-0">
                      Created by
                    </label>
                  </div>
                  <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                    <input
                      type="text"
                      className="form-control teenfontsize2"
                      value={data.publish_by}
                      readOnly
                      // placeholder="Enter Publish"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 px-4 pt-3 pb-3">
                <div className="row">
                  <div className="col-md-3 col-4 d-flex align-items-start" style={{position:'relative',top:'6px'}}>
                    <label htmlFor="" className="form-label teenfontsize m-0">
                      Image
                    </label>
                  </div>
                  <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-start">
                   <img src={data.image} alt="404" height={'200px'} style={{objectFit:'contain'}} />
                  </div>
                </div>
              </div>
            
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Viewvoiceoftheday;
