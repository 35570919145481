import React, { useEffect, useRef, useState } from "react";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useLocation, useNavigate } from "react-router-dom";
import Loadercomp from "../../../components/Loadercomp";
import Breadcup from "../../../components/Breadcup";
import Multiselect from "multiselect-react-dropdown";
import { useGetConcentratelistQuery } from "../../../store/api/concentrate-api";
import { usePostVoiceofthedayMutation, usePutVoiceofthedayMutation } from "../../../store/api/voiceofthedayapi";

const Updatevoiceoftheday = () => {
  const nvg = useNavigate();
  const location = useLocation()
  const [loading, setloading] = useState(false);
  const [meditationresponse, setmeditationresponse] = useState("");
  const [meditationerror, setmeditationerror] = useState("");
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [defvalue, setdefvalue] = useState({});
  const multiselectRef = useRef(null);
  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };
console.log("23456",defvalue);

  const onSelect = (selectedList) => {

    const idsArray = selectedList.map(item => item.id);
    console.log("seleddd",idsArray);
    setFormData({ ...formData, 'concentrate': idsArray });
  };

  // console.log("total value os sis is",showoptions)

  const onRemove = (selectedList) => {
    if(selectedList.length === 0){
      setFormData({ ...formData, 'concentrate': [] });
    }else{
      const idsArray = selectedList.map(item => item.id);
      setFormData({ ...formData, 'concentrate': idsArray });
    }
  };

  const {data: concentratelist,isLoading,refetch,} = useGetConcentratelistQuery();

  const [formData, setFormData] = useState({
    title: "",
    content: "",
    concentrate: [],
    image: null,
  });

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle file change
  const handleFileChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };
  

  const [Updatevoiceoftheday] = usePutVoiceofthedayMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const data = new FormData();
    console.log("this is the code ",formData.title,formData.content,formData.concentrate,formData.image);
    if(formData.title != ''){
    data.append("title", formData.title);
    }
    if(formData.content != ''){
      data.append("content", formData.content);
    }
    if(formData.concentrate.length > 0){
      data.append("concentrates", formData.concentrate);
    }
    if(formData.image != null){
      data.append("image", formData.image);
    }
    const pro = Updatevoiceoftheday({data,id:defvalue.id});
    console.log("create api response", pro);
    setmeditationresponse("Uploading...");
    const response = await pro;
    if (response.data.id) {
      setmeditationresponse("Uploaded Successfully");
      setmeditationerror("");
      setTimeout(() => {
        setmeditationresponse("");
        nvg("/voice-of-the-day-list");
      }, 3500);
    } else {
      setmeditationerror("Something Went Wrong Please Try Again");
      setmeditationresponse("");
    }
  
};

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])


  useEffect(()=>{
if(location.state.data){
  setFormData({ ...formData, 'title': location.state.data.title,'content': location.state.data.content });
  setdefvalue(location.state.data);
}


console.log("check one",location.state);
  },[])
  return (
    <div style={{ width: "100%", minHeight: "100vh" }}>
      <div className="dashboardcontent px-2">
      <Breadcup name={"Voice of the day"} secondname={"Edit Voice of the day"} />
        <div
          className="container-fuild px-2 mb-1"
          style={{ overflow: "hidden" }}
        >
          <div className="row bg-white ">
            <div
              className="col-lg-12 d-flex justify-content-between py-2"
              style={{ color: "#0C5398" }}
            >
              <p
                className="m-0 customfont teenheadertitle py-2"
                style={{ color: "#62153C" }}
              >
               Edit Voice of the day
              </p>
              <div className="addnew d-block mb-2">
                <button
                  className="btn "
                  type="button"
                  onClick={() => {
                    nvg("/voice-of-the-day-list");
                  }}
                  style={{
                    background: "white",
                    color: "#00A192",
                    fontSize: "19px",
                    fontWeight: "550",
                  }}
                >
                  {" "}
                  X{" "}
                </button>
              </div>
            </div>
          </div>
        </div>

        {isLoading == true || loading == true ? (
          <div className="container-fuild bg-white">
            {" "}
            <div
              className="col-12 d-flex justify-content-center"
              style={{ gap: "4px", position: "absolute", width: "70%" }}
            >
              <div className="px-2">
                {" "}
                <Loadercomp size={100} />
              </div>
            </div>
          </div>
        ) : (
          <div className="container-fuild pb-4 pt-3 px-2 bg-white">
            <form onSubmit={handleSubmit}>
              <div
                className="row bg-white round"
                style={{
                  border: "1px solid #E0E0E0",
                  margin: "10px 0px",
                  borderRadius: "3px",
                }}
              >
                <div className="col-lg-6 px-4 pt-3">
                  <div className="row">
                    <div className="col-md-3 col-4 d-flex align-items-center">
                      <label htmlFor="" className="form-label teenfontsize m-0">
                        Title<span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                      <input
                        name="title"
                        type="text"
                        value={formData.title}
                        onChange={handleChange}
                        className="form-control teenfontsize2"
                        placeholder="Enter Title"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 px-4 pt-3">
                  <div className="row">
                    
                    <div className="col-md-3 col-4 d-flex align-items-center">
                      <label
                        htmlFor=""
                        className="form-label teenfontsize customw m-0"
                      >
                        Concentrate<span style={{ color: "red" }}>*</span>{" "}
                      </label>
                    </div>
                    <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end nextdiv">


                    <Multiselect options={concentratelist.concentrates} selectedValues={defvalue.concentrates} name="concentrate" displayValue="name" ref={multiselectRef} onSelect={onSelect}
                        style={{fontSize: "6px"}}
                        onRemove={onRemove} className="form-control" required  />    
                      {/* <select
                        name="concentrate"
                        value={formData.concentrate}
                        onChange={handleChange}
                        className="form-control teenfontsize2"
                        placeholder="Enter concentrate"
                        required
                      >
                        <option value="">
                          {" "}
                          <span style={{ color: "red" }}>
                            Select Concentrate
                          </span>
                        </option>
                        {concentratelist.concentrates.map((item) => (
                          <option value={item.id}>{item.name}</option>
                        ))}
                      </select> */}
                    </div>
                  </div>
                </div>


                <div className="col-lg-6 px-4 pt-3">
                  <div className="row">
                    <div className="col-md-3 col-4 d-flex align-items-center">
                      <label htmlFor="" className="form-label teenfontsize m-0">
                        Content<span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                      <input
                        name="content"
                        type="text"
                        value={formData.content}
                        onChange={handleChange}
                        className="form-control teenfontsize2"
                        placeholder="Enter Content"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 px-4 pt-3">
                  <div className="row">
                    <div className="col-md-3 col-4 d-flex align-items-center">
                      <label htmlFor="" className="form-label teenfontsize m-0">
                        Image<span style={{ color: "red" }}>*</span>{" "}
                      </label>
                    </div>
                    <div className="col-lg-9 col-md-8 col-8 d-flex">
                      <input
                        type="file"
                        name="image"
                        accept="image/*"
                        onChange={handleFileChange}
                        className="form-control teenfontsize2"
                        // required
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 px-4 pt-5">
                  <div className="row d-flex justify-content-end">
                    <div
                      className="px-2 d-flex justify-content-end pt-5 pb-2"
                      style={{ gap: "5px" }}
                    >
                      <button
                        type="button"
                        className="btn "
                        style={{
                          background: "#F2F2F2",
                          color: "#7C7878",
                          fontSize: "13px",
                          width: "fit-content",
                        }}
                        onClick={() => {
                          nvg("/meditationlist");
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn "
                        style={{
                          background: "#179F92",
                          color: "#fff",
                          fontSize: "13px",
                          width: "fit-content",
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>

                {meditationerror != "" ? (
                  <div className="col-lg-6 px-4 ">
                    <div className="row d-flex">
                      <div
                        className="px-2 d-flex pb-2 alert alert-danger"
                        role="alert"
                        style={{
                          gap: "5px",
                          position: "relative",
                          top: "-50px",
                        }}
                      >
                        {meditationerror}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {meditationresponse != "" ? (
                  <div className="col-lg-6 px-4 ">
                    <div className="row d-flex">
                      <div
                        className="px-2 d-flex  alert alert-success"
                        role="alert"
                        style={{
                          gap: "5px",
                          position: "relative",
                          top: "-50px",
                        }}
                      >
                        <span>{meditationresponse}</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </form>
          </div>
        )}

      </div>
    </div>
  );
};

export default Updatevoiceoftheday;
